import { Element } from 'react-scroll'
import tw, { styled } from 'twin.macro'
import { SuspenseImg } from 'components/SuspenseImg'

import 'styled-components/macro'

export const Container = styled(Element)(
  tw`relative block h-screen lg:grid lg:gap-x-16 lg:grid-cols-2 lg:-mt-28`,
)

export const AboutContainer = styled.section(tw`animate-slideUp lg:my-auto`)

export const ImageContainer = styled.section(tw`lg:my-auto`)

export const Introduction = styled.section(tw`mb-10 lg:text-left`)

export const Hello = styled.h1(
  tw`mb-4 text-5xl font-semibold lg:text-5xl xl:text-7xl`,
)

export const JobTitle = styled.h2(
  tw`dark:text-blueGray-300 text-blueGray-600 text-3xl lg:text-5xl`,
)

export const Description = styled.p(
  tw`mb-5 dark:text-blueGray-300 text-blueGray-600 text-lg lg:mb-0 xl:text-xl`,
)

export const SocialIcons = styled.div(
  tw`absolute left-0 right-0 flex gap-7 justify-between mx-14 lg:relative lg:mx-32`,
)

export const SelfImage = styled(SuspenseImg)(
  tw`mb-20 mt-16 mx-auto w-8/12 shadow-none lg:-mt-10 lg:mb-0`,
)

export const ArrowButton = styled.div(
  tw`absolute bottom-8 left-0 right-0 hidden m-auto w-20 text-center dark:text-blueGray-300 text-blueGray-900 text-4xl animate-slideUp lg:block`,
)
