import { createContext } from 'react'

export interface IThemeContext {
  theme: 'dark' | 'light'
  setTheme: (theme: IThemeContext['theme']) => void
}

export const ThemeContext = createContext<IThemeContext>({
  theme: 'dark',
  setTheme: () => {
    return
  },
})
