import { useContext } from 'react'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import tw from 'twin.macro'
import { ThemeContext } from 'ThemeContext'
import { SocialIcon } from 'components/SocialIcon'
import { Header, LinkContainer, ModeButton, Nav } from './Menu.styled'
import { Link } from './components/Link'

import 'styled-components/macro'

export const Menu = () => {
  const links = [
    {
      name: 'About',
      to: 'about',
    },
    {
      name: 'Experience',
      to: 'timeline',
    },
    {
      name: 'Currently Listening',
      to: 'listening',
    },
  ]

  const { theme, setTheme } = useContext(ThemeContext)

  return (
    <>
      <Header darkMode={theme === 'dark'}>
        <Nav>
          <LinkContainer>
            {links.map(({ name, to }) => (
              <Link key={name} to={to}>
                {name}
              </Link>
            ))}
            <SocialIcon
              href="https://www.linkedin.com/in/johnbdev"
              twStyle={tw`mr-5 lg:hidden`}
              icon={faLinkedin}
              iconSize="lg"
            />
            <SocialIcon
              href="https://github.com/jurchfield"
              twStyle={tw`mr-5 lg:hidden`}
              icon={faGithub}
              iconSize="lg"
            />
            <SocialIcon
              href="mailto:john@johnburchfield.dev"
              twStyle={tw`lg:hidden`}
              icon={faEnvelope}
              iconSize="lg"
            />
          </LinkContainer>
          <ModeButton
            role="button"
            aria-hidden="true"
            tabIndex={0}
            onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
          >
            {theme === 'dark' ? '🌝' : '🌚'}
          </ModeButton>
        </Nav>
      </Header>
    </>
  )
}
