import { IExperience } from '../..'
import { useHover } from '../../../../hooks/useHover'
import { Skill } from '../Skill'
import {
  Card,
  Spacer,
  YearContainer,
  YearHeader,
  ExperienceLink,
  JobHeader,
  Company,
  AccomplishmentsContainer,
  Accomplishment,
  SkillsContainer,
  MobileYearContainer,
} from './TimelineItem.styled'

import 'styled-components/macro'

export const TimelineItem = ({
  experience,
  index,
}: {
  experience: IExperience
  index: number
}) => {
  const { reference, isHovering } = useHover()

  const isReverse = index % 2 === 0

  return (
    <>
      <Card isReverse={isReverse}>
        <Spacer />
        <YearContainer $isHovering={isHovering} isReverse={isReverse}>
          <YearHeader>{experience.year}</YearHeader>
        </YearContainer>
        <ExperienceLink
          href={experience.href}
          css={[experience.tw]}
          ref={reference}
          target="_blank"
          rel="noreferrer"
        >
          <JobHeader>
            <Company>{experience.company}</Company>
            <div>{experience.title}</div>
          </JobHeader>
          <AccomplishmentsContainer>
            {experience.accomplishments?.map(a => (
              <Accomplishment key={a}>{a}</Accomplishment>
            ))}
          </AccomplishmentsContainer>
          <SkillsContainer>
            {experience.skills.map(skill => (
              <Skill key={skill.name} hovering={isHovering} skill={skill} />
            ))}
          </SkillsContainer>
        </ExperienceLink>
      </Card>
      <MobileYearContainer>
        <YearHeader>{experience.year}</YearHeader>
      </MobileYearContainer>
    </>
  )
}
