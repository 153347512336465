import { Suspense } from 'react'
import { useLocalStorage } from 'react-use'
import { createGlobalStyle } from 'styled-components'
import tw, { styled } from 'twin.macro'
import { IThemeContext, ThemeContext } from 'ThemeContext'
import { About } from './components/About'
import { CurrentlyListening } from './components/CurrentlyListening'
import { Menu } from './components/Menu'
import { Timeline } from './components/Timeline'

import 'styled-components/macro'

const GlobalStyle = createGlobalStyle<{ theme: IThemeContext['theme'] }>`
  body {
    ${({ theme }) =>
      theme === 'dark' ? tw`bg-blueGray-900` : tw`bg-blueGray-200`}
  }
  html {
    scroll-behavior: smooth;
  }
`

const Loading = () => <div tw="w-full h-full bg-blueGray-900" />

const Main = styled.div(
  tw`relative block min-w-full min-h-full text-blueGray-900 dark:text-white font-sans bg-blueGray-200 dark:bg-gradient-to-b dark:from-blueGray-800 dark:to-blueGray-500 transition-colors duration-300`,
)

const Content = styled.div(
  tw`mx-auto my-0 pt-24 px-6 max-w-screen-lg h-full lg:pt-28 lg:px-0 xl:max-w-screen-xl`,
)

export const App = () => {
  const [theme, setTheme] = useLocalStorage<IThemeContext['theme']>(
    'theme',
    'dark',
  )

  return (
    <ThemeContext.Provider value={{ theme: theme ?? 'dark', setTheme }}>
      <div className={theme}>
        <GlobalStyle theme={theme} />
        <Suspense fallback={<Loading />}>
          <Main>
            <Menu />
            <Content>
              <About />
              <Timeline />
              <CurrentlyListening />
            </Content>
          </Main>
        </Suspense>
      </div>
    </ThemeContext.Provider>
  )
}
