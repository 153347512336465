import tw, { styled } from 'twin.macro'

export const Header = styled.header<{ darkMode: boolean }>(({ darkMode }) => [
  tw`fixed z-30 py-4 w-full shadow-md animate-slide transition-colors backdrop-blur`,
  darkMode
    ? tw`bg-blueGray-800 bg-opacity-70`
    : tw`bg-blueGray-200 bg-opacity-60`,
])

export const LinkContainer = styled.div(tw`flex items-start lg:space-x-10`)

export const Nav = styled.nav(
  tw`flex items-center justify-between mx-auto px-5 w-full max-w-screen-lg lg:px-0 xl:max-w-screen-xl`,
)

export const ModeButton = styled.div(
  tw`flex items-center mx-0 text-right text-3xl outline-none select-none xl:text-4xl`,
)
