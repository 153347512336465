import {
  faCss3,
  faHtml5,
  faJs,
  faNodeJs,
  faReact,
  IconDefinition,
} from '@fortawesome/free-brands-svg-icons'
import tw, { TwStyle } from 'twin.macro'
import { Section } from '../Section'
import { TimelineItem } from './components/TimelineItem'

import 'styled-components/macro'

export interface ISkill {
  name: string
  icon: IconDefinition
}

export interface IExperience {
  company: string
  title: string
  href: string
  tw: TwStyle
  year: number
  accomplishments: string[]
  skills: ISkill[]
}

const experiences: IExperience[] = [
  {
    company: 'Shipt',
    title: 'Staff Engineer',
    href: 'https://www.shipt.com/',
    tw: tw`lg:hover:bg-green-600 bg-green-700`,
    year: 2022,
    accomplishments: [
      'Develop and test internal tooling for e-commerce related projects including product catalog and campaign management',
      'Create and maintain projects related to our React micro-frontend platform and our GraphQL aggregation layer',
      'Ensure proper coordination with dependencies and stakeholders during large scale projects',
    ],
    skills: [
      {
        name: 'HTML',
        icon: faHtml5,
      },
      {
        name: 'CSS',
        icon: faCss3,
      },
      {
        name: 'Node',
        icon: faNodeJs,
      },
      {
        name: 'React',
        icon: faReact,
      },
    ],
  },
  {
    company: 'BBVA',
    title: 'Technical Lead',
    href: 'https://www.bbva.com/',
    tw: tw`lg:hover:bg-blue-700 bg-blue-800`,
    year: 2017,
    accomplishments: [
      'Spearheaded the migration of the Credit Card Origination banker facing application from Angular JS to Polymer 2 and LitElement',
      'Managed a team of Senior and Junior developers.',
      'Maintained and provided production support for the legacy Angular JS Client Vista application',
    ],
    skills: [
      {
        name: 'HTML',
        icon: faHtml5,
      },
      {
        name: 'CSS',
        icon: faCss3,
      },
      {
        name: 'Node',
        icon: faNodeJs,
      },
      {
        name: 'JS',
        icon: faJs,
      },
    ],
  },
  {
    company: 'University of Alabama',
    title: 'Lead Teaching Asst.',
    href: 'https://www.ua.edu/',
    tw: tw`lg:hover:bg-red-700 bg-red-800`,
    year: 2016,
    accomplishments: [
      'Graded, and created grading criteria and rubrics for web development assignments',
      'Troubleshooted PC software and hardware issues',
      'Assisted with scheduling and training of junior teaching assistants ',
    ],
    skills: [
      {
        name: 'HTML',
        icon: faHtml5,
      },
      {
        name: 'CSS',
        icon: faCss3,
      },
      {
        name: 'JS',
        icon: faJs,
      },
    ],
  },
  {
    company: 'Fabric Guru',
    href: 'https://www.fabricguru.com/',
    title: 'Web Developer',
    tw: tw`lg:hover:bg-indigo-700 bg-indigo-800`,
    year: 2014,
    accomplishments: [
      'Assisted in the development of the e-commerce site and internal tools for uploading and editing products',
      'Designed logos and advertisements for web and social media use',
      'Integrated Google Analytics to track site performance',
    ],
    skills: [
      {
        name: 'HTML',
        icon: faHtml5,
      },
      {
        name: 'CSS',
        icon: faCss3,
      },
      {
        name: 'JS',
        icon: faJs,
      },
    ],
  },
]

export const Timeline = () => {
  return (
    <Section header="A Brief History" name="timeline">
      <div tw="container relative mx-auto h-full lg:pb-20">
        <div tw="absolute left-1/2 h-full border dark:border-blueGray-100 border-blueGray-900 dark:border-opacity-20 border-opacity-80" />
        {experiences.map((experience, index) => (
          <TimelineItem
            index={index}
            key={experience.company}
            experience={experience}
          />
        ))}
      </div>
    </Section>
  )
}
