import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import tw, { styled } from 'twin.macro'
import { ISkill } from '..'

import 'styled-components/macro'

const SkillContainer = styled.div<{
  isHovered: boolean
}>(({ isHovered }) => [
  tw`flex items-center justify-between p-2 text-blueGray-800 bg-blueGray-100 rounded-md shadow transform transition-all duration-300 ease-in-out`,
  isHovered && tw`scale-105`,
])

const SkillIcon = styled.div(tw`flex items-center m-auto lg:m-0 lg:pl-3`)

const SkillName = styled.div(tw`hidden text-xs lg:block lg:pr-3 lg:text-sm`)

export const Skill = ({
  skill: { name, icon },
  hovering: isHovered,
}: {
  skill: ISkill
  hovering: boolean
}) => (
  <SkillContainer isHovered={isHovered}>
    <SkillIcon>
      <FontAwesomeIcon size="lg" icon={icon} />
    </SkillIcon>
    <SkillName>{name}</SkillName>
  </SkillContainer>
)
