/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import 'twin.macro'
import 'styled-components/macro'

interface ImageCache {
  __cache: { [key: string]: boolean | Promise<unknown> }
  read: (source: string | undefined) => void
}

const imageCache: ImageCache = {
  __cache: {},
  read(source: string | undefined) {
    if (!source) {
      return
    }

    if (!this.__cache[source]) {
      this.__cache[source] = new Promise(resolve => {
        const image = new Image()

        image.src = source
        image.addEventListener('load', () => {
          this.__cache[source] = true
          resolve(this.__cache[source])
        })
      }).then(() => {
        this.__cache[source] = true
      })
    }

    if (this.__cache[source] instanceof Promise) {
      throw this.__cache[source]
    }
  },
}

export const SuspenseImg = ({
  src,
  alt,
  ...properties
}: React.ImgHTMLAttributes<HTMLImageElement>) => {
  imageCache.read(src)

  return (
    <img
      src={src}
      alt={alt}
      tw="w-full rounded-xl shadow animate-slideUpFast"
      {...properties}
    />
  )
}
