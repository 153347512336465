import { useRef, useState, useEffect } from 'react'

export const useHover = () => {
  const [isHovering, setIsHovering] = useState(false)

  const reference = useRef<HTMLAnchorElement>(null)

  const handleMouseOver = () => setIsHovering(true)
  const handleMouseOut = () => setIsHovering(false)

  useEffect(
    () => {
      const node = reference.current
      if (node) {
        node.addEventListener('mouseover', handleMouseOver)
        node.addEventListener('mouseout', handleMouseOut)

        return () => {
          node.removeEventListener('mouseover', handleMouseOver)
          node.removeEventListener('mouseout', handleMouseOut)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reference.current], // Recall only if ref changes
  )

  return { reference, isHovering }
}
