import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { scroller } from 'react-scroll'
import tw from 'twin.macro'
import me from '../../assets/me-min.png'
import { SocialIcon } from '../SocialIcon'
import {
  Container,
  AboutContainer,
  Introduction,
  Hello,
  JobTitle,
  Description,
  SocialIcons,
  SelfImage,
  ImageContainer,
  ArrowButton,
} from './About.styled'

import 'styled-components/macro'

export const About = () => (
  <Container name="about">
    <SelfImage src={me} alt="john burchfield" tw="-mt-2 mb-0 lg:hidden" />
    <AboutContainer>
      <Introduction>
        <Hello>Hi, I&apos;m John.</Hello>
        <JobTitle>A Software Engineer.</JobTitle>
      </Introduction>
      <Description>
        My passion for the last 6 years has been delivering elegant user
        experiences. Currently focused on developing the admin platform at
        Shipt.
      </Description>
    </AboutContainer>
    <ImageContainer>
      <SelfImage src={me} alt="john burchfield" tw="hidden lg:block" />
      <SocialIcons>
        <SocialIcon
          href="https://www.linkedin.com/in/johnbdev"
          twStyle={tw`hidden hover:rotate-6 animate-slideUpSlow lg:block`}
          icon={faLinkedin}
          iconSize="3x"
        />
        <SocialIcon
          href="https://github.com/jurchfield"
          twStyle={tw`hidden animate-slideUpFast lg:block`}
          icon={faGithub}
          iconSize="3x"
        />
        <SocialIcon
          href="mailto:john@johnburchfield.dev"
          twStyle={tw`hidden hover:-rotate-6 animate-slideUp lg:block`}
          icon={faEnvelope}
          iconSize="3x"
        />
      </SocialIcons>
    </ImageContainer>
    <ArrowButton
      onClick={() =>
        scroller.scrollTo('timeline', {
          smooth: true,
          offset: -100,
          duration: 500,
        })
      }
      role="button"
    >
      ▼
    </ArrowButton>
  </Container>
)
