import React from 'react'
import { Element } from 'react-scroll'

import 'twin.macro'
import 'styled-components/macro'

export const Section = ({
  header,
  children,
  name,
}: React.PropsWithChildren<{ header: string; name: string }>) => {
  return (
    <Element name={name} tw="mt-9 animate-slideUpSlow lg:mt-14">
      <h2 tw="mb-12 text-center text-3xl font-medium lg:text-4xl">{header}</h2>
      {children}
    </Element>
  )
}
