import { useContext } from 'react'
import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import tw, { TwStyle } from 'twin.macro'
import { ThemeContext } from 'ThemeContext'

import 'styled-components/macro'

interface SocialIconProperties extends React.HTMLProps<HTMLAnchorElement> {
  twStyle?: TwStyle
  icon: IconDefinition
  iconSize?: SizeProp
}

export const SocialIcon = ({
  twStyle,
  href,
  icon,
  iconSize,
}: SocialIconProperties) => {
  const { theme } = useContext(ThemeContext)

  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      css={[
        tw`lg:hover:scale-110 transform transition duration-200 ease-in-out`,
        twStyle,
      ]}
    >
      <FontAwesomeIcon
        color={theme === 'dark' ? 'rgb(148,163,184)' : 'rgb(71,85,105)'}
        icon={icon}
        size={iconSize}
      />
    </a>
  )
}
