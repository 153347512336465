import tw, { styled } from 'twin.macro'

import 'styled-components/macro'

export const Card = styled.div<{ isReverse: boolean }>(({ isReverse }) => [
  tw`flex items-center justify-between mb-10 w-full lg:mb-0`,
  isReverse && tw`flex-row-reverse`,
])

export const ExperienceLink = styled.a(
  tw`lg:hover:scale-105 order-1 p-5 w-full text-white no-underline rounded-lg shadow cursor-pointer transform transition duration-200 ease-in-out lg:w-5/12`,
)

export const YearContainer = styled.div<{
  $isHovering: boolean
  isReverse: boolean
}>(({ $isHovering, isReverse }) => [
  tw`z-20 hidden items-center order-1 w-14 h-8 text-blueGray-700 dark:text-blueGray-900 dark:bg-blueGray-100 bg-blueGray-200 rounded-lg shadow transform transition-all duration-200 lg:flex`,
  isReverse ? $isHovering && tw`lg:-rotate-6` : $isHovering && tw`lg:rotate-6`,
])

export const YearHeader = styled.h1(tw`mx-auto text-lg font-medium`)

export const Spacer = styled.div(tw`order-1 lg:w-5/12`)

export const JobHeader = styled.div(
  tw`flex items-center justify-between mb-5 text-lg`,
)

export const Company = styled.div(tw`font-semibold`)

export const AccomplishmentsContainer = styled.ul(
  tw`max-w-full text-sm lg:text-base`,
)

export const Accomplishment = styled.li(tw`mb-2 list-inside list-disc`)

export const SkillsContainer = styled.div(tw`grid gap-4 grid-cols-4 mt-5`)

export const MobileYearContainer = styled.div(
  tw`relative z-20 flex items-center mb-10 mx-auto w-14 h-8 text-blueGray-700 dark:text-blueGray-900 dark:bg-blueGray-100 bg-blueGray-200 rounded-lg shadow lg:hidden`,
)
