import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import tw, { styled } from 'twin.macro'
import { SuspenseImg } from 'components/SuspenseImg'
import { useHover } from 'hooks/useHover'
import { IAlbum } from '..'

import 'styled-components/macro'

export const PlayIcon = styled(FontAwesomeIcon)<{ $isHovering: boolean }>(
  ({ $isHovering }) => [
    tw`absolute -top-6 bottom-0 left-0 right-0 m-auto opacity-0 transform transition-all duration-300`,
    $isHovering && tw`lg:opacity-100`,
  ],
)

export const AlbumCard = ({
  album: { album, artist, href, image },
}: {
  album: IAlbum
}) => {
  const { reference, isHovering } = useHover()

  return (
    <a
      tw="lg:hover:scale-105 relative block mb-12 w-full no-underline transform transition-all duration-200 lg:mb-0"
      ref={reference}
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      <SuspenseImg
        css={[
          isHovering && tw`lg:opacity-50`,
          tw`transform transition-all duration-500`,
        ]}
        src={image}
        alt="album image"
      />
      <PlayIcon icon={faPlay} size="4x" $isHovering={isHovering} />
      <div tw="ml-1 mt-2">
        <div tw="text-blueGray-900 dark:text-white font-semibold">{album}</div>
        <div tw="dark:text-blueGray-300 text-blueGray-700">{artist}</div>
      </div>
    </a>
  )
}
