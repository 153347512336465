import { Section } from '../Section'
import { AlbumCard } from './components/AlbumCard'

import 'twin.macro'
import 'styled-components/macro'

export interface IAlbum {
  artist: string
  album: string
  image: string
  href: string
}

const albums: IAlbum[] = [
  {
    artist: 'Wes Bos & Scott Tolinski',
    album: 'Syntax',
    image:
      'https://cdn-images-1.listennotes.com/podcasts/syntax-tasty-web-development-treats-wes-bos-HBLGrV82oAs.1400x1400.jpg',
    href: 'https://syntax.fm',
  },
  {
    artist: 'Justin, Travis, and Griffin McElroy',
    album: 'My Brother, My Brother, and Me',
    image:
      'https://vurbl.com/wp-content/uploads/2020/06/My-Brother-My-Brother-and-Me-Podcast.jpg',
    href: 'https://maximumfun.org/podcasts/my-brother-my-brother-and-me/',
  },
  {
    artist: 'Altin Gün',
    album: 'Âlem',
    image: 'https://f4.bcbits.com/img/a2819923954_16.jpg',
    href: 'https://altingunband.bandcamp.com/album/lem',
  },
  {
    artist: 'YĪN YĪN',
    album: 'The Rabbit That Hunts Tigers',
    image: 'https://f4.bcbits.com/img/a2791914862_16.jpg',
    href: 'https://yinyin.bandcamp.com/album/the-rabbit-that-hunts-tigers',
  },
  {
    artist: 'Parquet Courts',
    album: 'Sympathy for Life',
    image: 'https://f4.bcbits.com/img/a3739988549_16.jpg',
    href: 'https://parquetcourts.bandcamp.com/album/sympathy-for-life',
  },
  {
    artist: 'Weyes Blood',
    album: 'Titanic Rising',
    image: 'https://f4.bcbits.com/img/a1673180911_16.jpg',
    href: 'https://weyesblood.bandcamp.com/album/titanic-rising',
  },
  {
    artist: 'Bo Burnham',
    album: 'Inside (The Songs)',
    image: 'https://m.media-amazon.com/images/I/61-SeDe0hjL._SL1200_.jpg',
    href: 'https://boburnham.lnk.to/Shop',
  },
  {
    artist: 'Kikagaku Moyo',
    album: 'Masana Temples',
    image: 'https://f4.bcbits.com/img/a2717866482_16.jpg',
    href: 'https://kikagakumoyoggb.bandcamp.com/album/masana-temples',
  },
  {
    artist: 'Video Age',
    album: 'Pleasure Line',
    image: 'https://f4.bcbits.com/img/a2592730900_16.jpg',
    href: 'https://videoage.bandcamp.com/album/pleasure-line',
  },
]

export const CurrentlyListening = () => {
  return (
    <Section header="Currently Listening" name="listening">
      <div tw="block gap-16 grid-cols-3 mb-20 lg:grid">
        {albums.map(album => (
          <AlbumCard key={album.image} album={album} />
        ))}
      </div>
    </Section>
  )
}
