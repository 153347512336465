import { Link as InternalLink } from 'react-scroll'
import tw, { styled } from 'twin.macro'

import 'styled-components/macro'

const StyledLink = styled(InternalLink)`
  ${tw`relative hidden max-w-max text-left text-base cursor-pointer select-none lg:block lg:text-base xl:text-base`}
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    ${tw`bg-blueGray-500 rounded-lg`};
    visibility: hidden;
    transform: scaleX(0);
    transition: 0.25s linear;
  }
  &:hover:before,
  &:focus:before {
    visibility: visible;
    transform: scaleX(1);
  }
`

export const Link = ({
  to,
  children,
}: React.PropsWithChildren<{ to: string }>) => (
  <StyledLink smooth to={to} offset={-100} duration={500}>
    <span tw="dark:text-blueGray-300 text-blueGray-900 transition-colors">
      {children}
    </span>
  </StyledLink>
)
